import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export const journeyData = [
  {
    icon: "tradingItemOne",
    title: "1. Swift and Reliable Delivery",
    description:
      "Get your packages delivered in record time with FastCourier. We prioritize speed and reliability to ensure your shipments reach their destination promptly and securely.",
  },
  {
    icon: "tradingItemTwo",
    title: "2. Real-Time Tracking",
    description:
      "Stay informed with our real-time tracking feature. Monitor your shipment's progress from pick-up to delivery, giving you peace of mind every step of the way.",
  },
  {
    icon: "tradingItemThree",
    title: "3. Global Reach, Local Expertise",
    description:
      "With an extensive global network and deep local knowledge, FastCourier ensures your packages are delivered efficiently, whether across town or across the world.",
  },
  {
    icon: "tradingItemFour",
    title: "4. 24/7 Customer Support",
    description:
      "Our dedicated customer support team is available around the clock to assist you with any questions or concerns, ensuring a seamless delivery experience every time.",
  },
  // {
  //   icon: "tradingItemFive",
  //   title: "User-Friendly Interface",
  //   description:
  //     "Enjoy a seamless, intuitive experience designed for both beginners and experts, making navigation effortless.",
  // },
  // {
  //   icon: "tradingItemSix",
  //   title: "Secure and Reliable",
  //   description:
  //     "Play with confidence, knowing your data and transactions are securely protected, ensuring a trustworthy environment.",
  // },
];

export const serviceData = [
  {
    icon: "tradingItemOne",
    title: "1. Eco-Friendly Deliveries",
    description:
      "Contribute to a greener planet with our sustainable delivery options, reducing your carbon footprint without compromising on speed.",
  },
  {
    icon: "tradingItemTwo",
    title: "2. Same-Day Delivery",
    description:
      "Need it fast? Our same-day delivery service ensures your parcels are delivered within hours, perfect for urgent shipments.",
  },
  {
    icon: "tradingItemThree",
    title: "3. Customized Packaging",
    description:
      "Protect your items with our tailored packaging solutions, designed to keep your shipments safe and secure during transit.",
  },
  {
    icon: "tradingItemFour",
    title: "4. Delivery Alerts",
    description:
      "Receive instant notifications via SMS or email when your parcel reaches key milestones, keeping you informed every step of the way.",
  },
  {
    icon: "tradingItemFour",
    title: "5. Bulk Shipping Discounts",
    description:
      "Save more with our special rates for bulk shipments, ideal for businesses and frequent shippers looking to optimize their costs.",
  },
  {
    icon: "tradingItemFour",
    title: "6. Contactless Delivery",
    description:
      "Enjoy the convenience and safety of contactless delivery, ensuring your parcels are dropped off securely without any direct contact.",
  },
];

export const asschievementsData = [
  { title: "Deliveries Completed", count: "1,000,000+" },
  { title: "Happy Customers", count: "500,000+" },
  { title: "Service Areas", count: "200+" },
  { title: "Support Team", count: "24/7" },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const whyChooseData = [
  {
    icon: <IoIosCheckmarkCircleOutline size={30} />,
    title: "Same-Day Delivery",
  },
  {
    icon: <IoIosCheckmarkCircleOutline size={30} />,
    title: "International Shipping",
  },
  {
    icon: <IoIosCheckmarkCircleOutline size={30} />,
    title: "Package Tracking",
  },
  {
    icon: <IoIosCheckmarkCircleOutline size={30} />,
    title: "Bulk Shipping Solutions",
  },
  {
    icon: <IoIosCheckmarkCircleOutline size={30} />,
    title: "Secure Handling and Delivery",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is Swiftgofer?",
    content:
      " is a Swiftgofer fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with Swiftgofer?",
    content:
      "Download the Swiftgofer app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];

export const clientReview = [
  {
    icon: "startPlay2",
    title: "Dairy & Bakery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Grocery",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Ayurvedic",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
  {
    icon: "startPlay2",
    title: "Fruit & Vegetables",
    productNo: "4 Products",
  },
];
