import React from "react";
import { useLocation } from "react-router-dom";

const Detail = () => {
  const location = useLocation();
  const { data } = location.state || {};
  console.log(data, "-------------");

  const deliveryDetails = [
    { label: "Tracking ID", value: data?.data?.tracking_id },
    { label: "Date", value: data?.data?.delivery_date },
    { label: "State", value: data?.data?.delivery_state },
    { label: "City", value: data?.data?.delivery_city },
    { label: "Address", value: data?.data?.delivery_address },
    {
      label: "Email",
      value: data?.data?.delivery_person_contact_email,
    },
    {
      label: "Contact No",
      value: data?.data?.delivery_person_contact_no,
    },
  ];

  const otherDetails = [
    { label: "Site Name", value: data?.data?.site_name },
    { label: "Status", value: data?.data?.status },
    { label: "Current Place", value: data?.data?.current_place },
    { label: "Updated At", value: data?.data?.updated_at },
    { label: "Created At", value: data?.data?.created_at },
  ];

  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center pt-3">
              <div className="commantext">
                <h4>
                  Order
                  <span> Details</span>
                </h4>
                {/* <p>
                  <b>PRIME INFORMATION TECHNOLOGY PRIVATE LIMITED</b>
                </p> */}
              </div>
            </div>
          </div>

          <div className="order_main">
            <div className="detailcard">
              <h6 className="detail_heading">Delivery Detail</h6>
              <div>
                {deliveryDetails?.map((detail, index) => (
                  <div key={index} className="order_sub">
                    <h6>{detail.label}</h6>
                    <p>{detail.value}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="detailcard">
              <h6 className="detail_heading">Other Detail</h6>
              <div>
                {otherDetails?.map((detail, index) => (
                  <div key={index} className="order_sub">
                    <h6>{detail.label}</h6>
                    <p>{detail.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
