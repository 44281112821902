import React from "react";
import { Icon } from "../../ui";
import {
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";

const ContactUs = () => {
  const contactData = [
    {
      icon: <IoLocationOutline size={25} />,
      title: "Location",
      description:
        "B-404, JANKI AVENUE, UMELMAN, VASAI WEST, VASAI THANE, MAHARASHTRA - 401201.",
      url: "https://maps.app.goo.gl/kkrYLgeg688insn79",
    },

    {
      icon: <IoCallOutline size={25} />,
      title: "Contact No.",
      description: "(+91) 8541855720",
      url: "tel:+918541855720",
    },
    {
      icon: <IoMailOutline size={25} />,
      title: "Email Address",
      description: "bhardwajmoonr@gmail.com",
      url: "mailto:bhardwajmoonr@gmail.com",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="comman_space">
          <div className="row justify-content-center my-2 pt-5">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  <span>Contact</span> Us
                </h4>
                <p>
                  <b>PRIME INFORMATION TECHNOLOGY PRIVATE LIMITED</b>
                </p>
              </div>
            </div>
          </div>
          <div className="contact_main">
            <div className="source_box">
              {contactData.map((item, index) => (
                <div className="source_boxsub" key={index}>
                  <div className="source_icon">{item.icon}</div>
                  <div>
                    <h4>{item.title}</h4>
                    <p>
                      <a href={item?.url} target="_blank">
                        {item.description}
                      </a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.6163540466755!2d72.81093697525942!3d19.373671142560124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ae99a22783d7%3A0xa930c6c057cbf8e1!2sJanki%20Avenue%2C%20PLATINUM%20ENCLAVE%2C%20Kaul%20Heritage%20City%2C%20Vasai%20West%2C%20Vasai-Virar%2C%20Sandor%2C%20Maharashtra%20401202!5e1!3m2!1sen!2sin!4v1722939512057!5m2!1sen!2sin"
              className="iframe"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
