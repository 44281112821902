import React from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import {
  IoCallOutline,
  IoLocationOutline,
  IoMailOutline,
} from "react-icons/io5";

const Footer = () => {
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    console.log(link, "ooooo");
    navigate("/");
  };

  const contactData = [
    {
      icon: <IoLocationOutline size={25} />,
      title: "Location",
      url: "https://maps.app.goo.gl/kkrYLgeg688insn79",
    },

    {
      icon: <IoCallOutline size={25} />,
      title: "Contact No.",
      url: "tel:+918541855720",
    },
    {
      icon: <IoMailOutline size={25} />,
      title: "Email Address",
      url: "mailto:bhardwajmoonr@gmail.com",
    },
  ];
  return (
    <>
      <footer className="position-relative ">
        <div className="footer">
          <div className="container">
            <div className="link-detail">
              <p>
                <NavLink to={"/"}>Home</NavLink>
              </p>
              <p>
                <NavLink to={"/about-us"}>About</NavLink>
              </p>
              <p>
                <NavLink to={"/service"}>Service</NavLink>
              </p>
              <p>
                <NavLink to={"/contact-us"}>Contact Us</NavLink>
              </p>
              <p>
                <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
              </p>
              <p>
                <NavLink to={"/terms-condition"}>Terms & Conditions</NavLink>
              </p>
            </div>
            <div className="foot_iconmain">
              {contactData.map((item, index) => (
                <div className="foot_icon" key={index}>
                  <a href={item?.url} target="_blank">
                    {item.icon}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 Swiftgofer</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
