import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import {
  accordionFullData,
  asschievementsData,
  journeyData,
  whyChooseData,
} from "./mock";
import { useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.zippyglide.com/api/orders/get-by-id",
        {
          id: inputValue,
        }
      );
      if (response.data) {
        navigate("/detail", { state: { data: response.data } });
      } else {
        setShowMessage(true);
        setInputValue("");
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setInputValue("");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setSuccess("");

    if (!validateEmail(formData.email)) {
      return;
    }

    setLoading(true);
    setSuccess("");

    try {
      const response = await axios.post(
        "http://192.168.132.77:8000/contactus",
        formData
      );

      // Simulate a delay to show the loader for 3 seconds
      setTimeout(() => {
        setSuccess("Form submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        });
        setLoading(false);
      }, 3000);
    } catch (error) {
      setTimeout(() => {
        setSuccess("Form submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        });
        setLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="hero-sec position-relative">
          <div className="container">
            <div className="row  align-items-center scale-up-center">
              <div className="col-lg-5">
                <div className="heroimg">
                  <Icon name="Heroimg" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 my-2 ">
                <h1>
                  <span> SwiftGofer </span>
                  Your Package, Our Priority-Delivered with
                  <span> Speed and Care</span>
                </h1>
                <p>
                  Experience the convenience of rapid deliveries with
                  SwiftGofer. Whether it’s across town or across the globe, we
                  ensure your parcels arrive on time, every time. Trust us to
                  handle your shipping needs with precision and care, giving you
                  peace of mind every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="mybtn">
          <div className="mybtnsub">
            <input
              type="text"
              placeholder="Track Shipment"
              className="form-control"
              value={inputValue}
              onChange={handleInputChange}
            />
            <Button
              className="whitebtn"
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? (
                <div className="loader"></div>
              ) : (
                <IoIosSearch size={26} />
              )}
            </Button>
          </div>
          {showMessage && (
            <div className="message pt-1">Order Details not found!</div>
          )}
        </div>
      </section>

      {/* <section>
        <div className="darkbg">
          <div className="container">
            <div className="get_app">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-4 col-md-8">
                  <h4>Get the App Now!</h4>
                  <p>
                    Download the Swiftgofer app now for a seamless fantasy
                    cricket experience. Join leagues, create teams, and win big!
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 text-md-end text-sm-start">
                  <a
                    type="button"
                    className="whitebtn"
                    href={apk}
                    download="funzone.apk"
                  >
                    Download App Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="start_withmain comman_space">
        <div className="container">
          <div className="row my-2">
            <div className="col-lg-8 col-md-10">
              <div className="commantext">
                <h4>
                  Discover the Benefits of <span>Choosing Swiftgofer</span> for
                  Your Delivery Needs
                </h4>
                <p>
                  Experience the excellence of Swiftgofer with our fast
                  delivery, real-time tracking, global reach, and 24/7 support.
                  We ensure your packages arrive safely and on time, every time.
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {journeyData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div className="start_withicon">
                    <Icon name={item?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container">
        <section>
          <div className="row align-items-center justify-content-center comman_space pt-0">
            {/* <div className="col-lg-5 ">
              <div className="aboutus_img">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div> */}
            <div className="col-lg-7">
              <div className="commantext text-center">
                <h4>
                  Our <span>Achievements</span>
                </h4>
                <p>Proudly delivering excellence with impressive results.s</p>
              </div>
              <div className="aboutus_textmain">
                {asschievementsData.map((item, index) => {
                  return (
                    <div key={index} className="aboutus_text">
                      <h4>{item?.count}</h4>
                      <p>{item?.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="whychoosemain comman_space">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="commantext">
                <h4>
                  Comprehensive <span>Courier</span> Solution
                </h4>
                <p>
                  Explore our diverse range of courier services designed to meet
                  all your shipping needs. From express deliveries to secure
                  handling, we offer solutions tailored to ensure your parcels
                  are delivered efficiently and reliably.
                </p>
              </div>
              {whyChooseData.map((item, index) => (
                <div className="d-flex">
                  <div className="why_choosebox">
                    <div>{item?.icon}</div>
                    <h5>{item?.title}</h5>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-4 order-1 order-lg-2 text-lg-center text-start">
              <Icon name="whyChoose" className="img-fluid whychoosicon" />
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row align-items-center comman_space">
          <div className="col-lg-6">
            <Icon name="contactUs" className="img-fluid" />
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmitForm} className="contact_form">
              <div className="d-flex gap-2">
                <div className="form_field w-100">
                  <label htmlFor="firstName">First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form_field w-100">
                  <label htmlFor="lastName">Last Name*</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form_field">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_field">
                <label htmlFor="phoneNumber">Phone Number*</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  className="form-control"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="form_field">
                <label htmlFor="Address">Address*</label>
                <input
                  type="text"
                  name="Address"
                  className="form-control"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <div className="form_field">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <Button
                className="primarybtn w-100"
                type="submit"
                disabled={loading}
              >
                {loading ? <div className="loader"></div> : "Submit"}
              </Button>
              {success ? success : null}
            </form>
          </div>
        </div>
        <div className="comman_space pt-0">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>Swiftgofer.com </span>
                </h4>
                <p>
                  Have questions? Our FAQ section provides essential answers to
                  help you get started and enjoy our platform easily.
                </p>
              </div>
            </div>
          </div>
          <div
            className="accordion accordion-flush selfaccord"
            id="accordionFlushExample"
          >
            {accordionFullData?.slice(0, 4)?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}1`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}1`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}1`}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
