import React from "react";

const TermsCondition = () => {
  return (
    <div className="container">
      <div className="comman_space">
        <div className="row justify-content-center my-2 pt-5">
          <div className="col-lg-8 col-md-10 text-center pt-3">
            <div className="commantext">
              <h2 className="mb-4">Terms and Conditions</h2>
              <p className="lead mb-4">
                Welcome to Swiftgofer.com, a website operated by PRIME
                INFORMATION TECHNOLOGY PRIVATE LIMITED (“Swiftgofer”). The
                following terms and conditions govern your use of Swiftgofer’s
                website and services. By accessing or using Swiftgofer.com, you
                agree to be bound by these terms and conditions. If you do not
                agree with these terms, please do not use our website.
              </p>
            </div>
          </div>
        </div>

        <div className="policy_main">
          <h4>Definitions</h4>
          <p>
            “Content” refers to all information, graphics, products, features,
            functionality, services, and links available on Swiftgofer.com,
            including but not limited to ‘Tracking’, ‘Service Guide’, and
            ‘Corporate Solutions’.
          </p>
          <p>
            “Swiftgofer” refers to PRIME INFORMATION TECHNOLOGY PRIVATE LIMITED,
            located at B-404, JANKI AVENUE, UMELMAN, VASAI WEST, VASAI THANE,
            MAHARASHTRA - 401201.
          </p>
          <p>“You” refers to the user or viewer of Swiftgofer.com.</p>
        </div>

        <div className="policy_main">
          <h4>1. Use of Swiftgofer Website</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer.com is intended solely for use by current and potential
              Swiftgofer customers to interact with Swiftgofer. The site is
              provided for purposes such as shipping, tracking, obtaining
              shipping rates, receiving invoices, making payments, identifying
              drop-off locations, and preparing international documents.
            </li>
            <li>
              Unauthorized use of Swiftgofer.com, including but not limited to
              accessing services or information for third parties, is strictly
              prohibited.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>2. User Registration</h4>
          <ul className="policy_ul">
            <li>
              Users may register on Swiftgofer.com to access interactive
              features. By registering, you agree to provide accurate and
              current information about yourself. You are responsible for
              maintaining the confidentiality of your password and account
              information and for all activities under your account.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>3. Changes to the Website</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer reserves the right to modify, suspend, or discontinue
              any part of the website or its content at any time without notice.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>4. Termination of Use</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer may, at its sole discretion, terminate or suspend your
              access to the website for any reason, including but not limited to
              violations of these terms and conditions.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>5. Ownership</h4>
          <ul className="policy_ul">
            <li>
              All content on Swiftgofer.com is the property of Swiftgofer and is
              protected by applicable intellectual property laws. Users may not
              copy, modify, distribute, or reverse engineer any part of the
              website or its content.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>6. Disclaimer of Warranty</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer.com and its content are provided “AS IS” without
              warranties of any kind, either express or implied, including but
              not limited to the implied warranties of merchantability and
              fitness for a particular purpose.
            </li>
            <li>
              Swiftgofer does not warrant that the website will be uninterrupted
              or error-free, nor does it make any warranty as to the results
              that may be obtained from the use of the website.
            </li>
          </ul>
        </div>
        <div className="policy_main">
          <h4>7. Limitation of Liability</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer will not be liable for any damages arising from the use
              or inability to use the website, including but not limited to
              direct, indirect, incidental, or consequential damages.
            </li>
            <li>
              In no event shall Swiftgofer’s total liability exceed INR 100 for
              domestic consignments and USD 100 for international consignments.
            </li>
          </ul>
        </div>
        <div className="policy_main">
          <h4>8. Indemnity</h4>
          <ul className="policy_ul">
            <li>
              You agree to indemnify and hold Swiftgofer harmless from any
              claims arising from your use of the website or violation of these
              terms and conditions.
            </li>
          </ul>
        </div>

        <div className="policy_main">
          <h4>9. to Other Websites</h4>
          <ul className="policy_ul">
            <li>
              Swiftgofer.com may contain links to third-party websites.
              Swiftgofer is not responsible for the content or privacy practices
              of these websites and does not endorse or make any representations
              about them.
            </li>
          </ul>
        </div>
        <div className="policy_main">
          <h4>10. Governing Law</h4>
          <ul className="policy_ul">
            <li>
              These terms and conditions are governed by the laws of the
              Republic of India. Any disputes arising from these terms or your
              use of Swiftgofer.com will be subject to the exclusive
              jurisdiction of the courts in Manipur, India.
            </li>
          </ul>
        </div>
        <div className="policy_main">
          <h4>Contact Information</h4>
          <p>
            For any queries regarding these terms and conditions, please contact
            us at:
          </p>
          <p>PRIME INFORMATION TECHNOLOGY PRIVATE LIMITED</p>
            <p>B-404, JANKI AVENUE, UMELMAN,</p>
            <p>VASAI WEST, VASAI THANE,</p>
            <p>MAHARASHTRA - 401201.</p>
            <p>Email : bhardwajmoonr@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
